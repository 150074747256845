<template>
  <div v-if="Object.keys(dispute_detail).length !== 0">
    <div class="mb-2">
      <b-modal id="dispute-detail-modal" title="Dispute Details" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
          <table class="table table-sm table-bordered text-center">
            <tr>
              <th>Hub Name</th>
              <td>{{ dispute_detail.hub.name }}</td>
            </tr>
            <tr>
              <th>Requisition No.</th>
              <td>{{ dispute_detail.requisition_item.requisition.requisition_number }}</td>
            </tr>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

export default {
  name: 'dispute_details',
  props: ['dispute_detail'],
  components: { },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style>

</style>
