<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-3" v-if="!user.role.includes('hub_in_charge')">
              <select v-model="search_data.hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
            </div>
            <div class="col-md-3">
              <select v-model="search_data.dispute_type_id" id="dispute_type_id" name="dispute_type_id" class="form-control form-control-sm">
                <option :value="''">Select a dispute type</option>
                <option v-for="(dispute_type, index) in dispute_types" :key="index" :value="dispute_type.id">{{ dispute_type.dispute_type_desc }}</option>
              </select>
            </div>
            <div class="col-md-3">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-3">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12 mb-1">
        <a-button v-if="requisition_items.total > 0"  title="Download" type="button" id="export-table" @click="onExcelExport" class="btn btn-success pull-right mr-2">
          <i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i> Excel
        </a-button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="product_dispute_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Unit</th>
              <th>Transfer Qty</th>
              <th style="color: red">Dispute Qty</th>
              <th style="color: green">Dispute Type</th>
              <th>Dispute Date</th>
              <th>Dispute Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition_item, i) in requisition_items.data" :key="i">
              <td>{{ requisition_items.from + i}}</td>
              <td>{{ requisition_item.product.name }}</td>
              <td>{{ requisition_item.product.unit }}</td>
              <td>{{ requisition_item.transfer_amount }}</td>
              <td style="color: red">{{ requisition_item.dispute_amount }}</td>
              <td style="color: green">{{ requisition_item.dispute_type ? requisition_item.dispute_type.dispute_type_desc : ''}}</td>
              <td>{{ customDate(requisition_item.created_at) }}</td>
              <td>
                <a-tag color="#f50" v-if="requisition_item.dispute_status == 'Pending'">Pending</a-tag>
                <a-tag color="#87d068"  v-if="requisition_item.dispute_status == 'Checked'">Received</a-tag>
              </td>
              <td>
                <a  class="btn btn-sm btn-info mr-1" href="javascript: void(0);" @click.prevent="disputeDetail(requisition_item), $bvModal.show('dispute-detail-modal')"><i class="fa fa-info-circle mr-1"></i>Details</a>
                <span v-if="user.role.includes('store_in_charge')">
                    <a-button v-if="requisition_item.dispute_status == 'Pending'" class="btn btn-sm btn-success" :loading="btnCheckLoading && i == index" @click.prevent="checkProduct(requisition_item.id)"><i class="fa fa-check mr-1" aria-hidden="true"></i>Received</a-button>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisition_items.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisition_items" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <dispute-details :dispute_detail="dispute_detail"></dispute-details>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import XLSX from 'xlsx'
import disputeDetails from '@/views/requisitionProductDispute/partial/dispute_details'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { Empty, disputeDetails },
  data() {
    return {
      hubs: [],
      dispute_types: [],
      dispute_detail: {},
      requisition_items: {},
      btnLoading: false,
      btnCheckLoading: false,
      loading: false,
      flag: false,
      index: -1,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        hub_id: '',
        dispute_type_id: '',
        date_range: '',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
          this.dispute_types = data.dispute_types
          if (data.dispute_types) {
            this.search_data.dispute_type_id = data.dispute_types[0].id
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.flag = true
      this.loading = true
      this.btnLoading = true
      apiClient.post('api/hub/requisition-product/dispute/search', this.search_data)
        .then(response => {
          this.btnLoading = false
          this.loading = false
          this.flag = true
          this.requisition_items = response.data.requisition_items
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/requisition-product/dispute/search?page=' + page, this.search_data)
        .then(response => {
          this.requisition_items = response.data.requisition_items
        })
    },
    onExcelExport(evt) {
      var tbl = document.getElementById('product_dispute_list')
      var wb = XLSX.utils.table_to_book(tbl)
      XLSX.writeFile(wb, 'products-dispute.xlsx')
    },
    disputeDetail(requisitionItem) {
      this.dispute_detail = []
      this.dispute_detail = requisitionItem
    },
    checkProduct(requisitionTransferId) {
      if (confirm('Do you really accept this items?')) {
        this.btnCheckLoading = true
        apiClient.post('api/admin/requisition-transfer-check', { requisitionTransferId: requisitionTransferId })
          .then(response => {
            this.btnCheckLoading = false
            if (!response.data.error) {
              this.search()
              this.$notification.success({
                message: response.data.message,
              })
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
